import { n as normalizeComponent } from "./index.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Method Code" } }, [_c("select-input", { key: "methodId", attrs: { "label": "Method Type", "reference": "sterilization.common.methods", "rules": "required", "source": "id", "source-label": "name", "span": 16 } }), _c("date-picker", { key: "issueDate", attrs: { "label": "Issue Date", "format-date": "DD/MM/YYYY", "rules": "required", "span": 8 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateDataEntry"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateDataEntry = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-data-entries" }, [_c("resource", { attrs: { "name": "sterilization.common.methods", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.common.stacking-patterns", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.data-entries", "api-url": _vm.apiUrl, "redirect-route": "/sterilization-specifications/data-entries/:id" } }, [_c("create-data-entry")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateDataEntry
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
